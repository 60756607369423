<template>
    <NuxtLayout name="default">
        <GameMenu name="Grand Piece Online" image="/images/games/icons/gpo.png" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Items', to: '/gpo/items' },
    { title: 'Trade Calculator', to: '/gpo/calculate' },
    { title: 'Trade Ads', to: '/gpo/ads' },
    { title: 'Value Changes', to: '/gpo/value-changes' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Grand Piece Online | RoValue` : 'Grand Piece Online - RoValue'
    }
})

useServerSeoMeta({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Grand Piece Online | RoValue` : 'Grand Piece Online - RoValue'
    }
})
</script>
